import React from 'react';

import { Grid, Column } from "@carbon/react";

import "./HomeContent.scss";

export default function ContactUs() {
    return (<div>
        <main>
            <Grid>
                <Column sm={4} md={6} lg={8}><h1>Contact CampTaken</h1>
                    <div>
                        <b>email:</b><br/>
                        info@camptaken.com
                    </div>

                    <div style={{marginTop:"16px"}}>
                        <b>Phone:</b><br/>
                        (512) 677-7393
                    </div>
                </Column>
            </Grid>
        </main>
    </div>)
}